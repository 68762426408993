<template>
  <!-- Sidebar pour l'historique des chats et utilisateurs -->
  <aside class="messaging-chat-history-section">
    <!-- Liste des utilisateurs -->
    <div class="user-list">
      <header>
        <span
          v-if="isInLayout"
          class="toggle-users-list"
          @click="toggleChatOpen"
        >
          <i v-if="isChatOpen" class="fas fa-chevron-down"></i>
          <i v-else class="fas fa-chevron-up"></i>
        </span>
        <h3>Amis</h3>
      </header>
      <ul
        v-show="isChatOpen"
        v-if="users.length > 0"
        class="user-list"
        ref="userList"
        @scroll="onScroll"
      >
        <li
          v-for="user in users"
          :key="user.id"
          @click="selectUser(user)"
          :class="{ selected: user.id === currentUser.id }"
          class="user-item"
        >
          <div
            class="connection-indicator"
            :class="{ online: user.connected, offline: !user.connected }"
            :title="user.connected ? 'Connecté' : 'Déconnecté'"
          ></div>
          <UserAvatar :width="48" :user="user" />

          <div class="flex">
            <div class="user-info">
              <span class="user-name title"
                >{{ user.first_name }} {{ user.last_name }}</span
              >
              <!--
                <span
                v-if="unreadMessages[user.first_name + ' ' + user.last_name]"
                class="unread-badge"
                >!</span
                >
              -->
            </div>
            <div>
              <span class="user-job job">{{ user.metier }}</span>
            </div>
          </div>
        </li>
      </ul>
      <p v-else>Aucun utilisateur trouvé</p>
    </div>
  </aside>
</template>

<script>
  import UserAvatar from '@/components/views-models/profil/UserAvatar.vue';
  import { axiosInstance } from '../../services/axios';
  export default {
    props: {
      // Spécifie si le composant appartient au layout ou si c'est le composant dans la page messagerie
      isInLayout: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data() {
      return {
        // Si le composant n'appartient pas au layout, on affiche le chat ouvert par défaut, sinon on se base sur l'état du localStorage
        isChatOpen: this.isInLayout ? false : true,
        currentUser: this.$store.getters.getUser,
        users: [],
        selectedTab: 0,
        currentPage: 1,
        totalPages: 1,
        isLoading: false,
      };
    },
    components: {
      UserAvatar,
    },
    methods: {
      toggleChatOpen() {
        this.isChatOpen = !this.isChatOpen;
        if (this.isInLayout) {
          this.$emit('toggleChatOpen', this.isChatOpen);
        }
      },
      async fetchUsers(page = 1) {
        if (this.isLoading || page > this.totalPages) return;
        this.isLoading = true;

        try {
          // TODO : Placer ceci dans un service ?
          const response = await axiosInstance.get(
            `/best_profils/?page=${page}`
          );
          this.users = [...this.users, ...response.data.results];
          console.log({ 'users from chatList': this.users });

          this.totalPages = Math.ceil(response.data.count / 9);
          this.currentPage = page;
        } catch (error) {
          console.error(
            'Erreur lors de la récupération des utilisateurs :',
            error
          );
        } finally {
          this.isLoading = false;
        }
      },
      goToPage(page) {
        if (page > 0 && page <= this.totalPages) {
          // console.log('Changement de page :', page);
          this.fetchUsers(page);
        } else {
          console.log('Page invalide ou déjà à la dernière page');
        }
      },
      onScroll() {
        const list = this.$refs.userList;
        if (list.scrollTop + list.clientHeight >= list.scrollHeight - 10) {
          if (this.currentPage < this.totalPages) {
            this.fetchUsers(this.currentPage + 1);
          }
        }
      },
      selectUser(user) {
        console.log('selectUser called from child: ', user);
        this.$emit('selectUser', user);
        this.$emit('lastConversation', user);
      },
    },
    mounted() {
      console.log('amis', this.currentUser.friends);

      this.fetchUsers(this.currentPage);
    },
  };
</script>

<style scoped>
  .exit-fullscreen-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
  }
  .exit-fullscreen-button img {
    width: 33px;
    height: 33pxx;
  }
  .fullscreen {
    width: 100%;
    height: 100%;
  }

  .fullscreen-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
  }

  .fullscreen-button img {
    width: 33px;
    height: 33px;
  }

  .video-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .video-controls button:hover {
    background-color: #33363a;
  }
  .video-controls button {
    padding: 6px 12px;
    border: none;
    border-radius: 5px;
    background-color: #26282b;
    color: #333;
    cursor: pointer;
  }
  .video-controls {
    position: relative;
    bottom: 10px;
    display: flex;
    gap: 10px;
    background: rgb(38 40 43);
    padding: 6px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    align-items: center;
    justify-content: center;
  }

  .webcam-on {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
    gap: 24px;
  }
  .webcam-video {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .unread-badge {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: red;
    display: inline-block;
    margin-left: 5px;
  }
  .job {
    color: #7f7272;
  }
  .title {
    font-size: 18px;
    font-weight: 600;
  }
  .flex {
    display: flex;
    flex-direction: column;
  }
  aside {
    width: 350px;
  }
  .fa-chevron-down:before {
    font-size: 20px;
  }
  .user-list {
    position: relative;
    width: 100%;
    background-color: #ffffff;
    border-right: 1px solid #ffffff;
    overflow-y: auto;
  }
  .user-list header {
    border-bottom: 1px solid #ccc;
    min-height: 48px;
    padding: 15px;
    display: flex;
  }
  .toggle-users-list {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 6px;
    transform: translateY(50%);
  }
  .fa-chevron-up:before {
    font-size: 20px;
  }
  .user-list h3 {
    font-size: 18px;
    font-weight: bold;
    color: black;
  }

  .user-list ul {
    list-style: none;
    padding: 0;
    height: 500px;
    overflow: auto;
  }

  .user-list li {
    display: flex;
    align-items: flex-start;
    padding: 7px;
    gap: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    flex-direction: row;
  }

  .user-list li:hover {
    background-color: #eee;
  }
  .connection-indicator {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    position: relative;
    left: 16px;
    top: 33px;
    box-shadow:
      0 2px 4px rgba(0, 0, 0, 0.3),
      inset 0 -2px 4px rgba(0, 0, 0, 0.5);
    background: radial-gradient(circle, #ffffff 10%, #e0e0e0 70%, #9e9e9e 100%);
  }
  .connection-indicator.online {
    background: radial-gradient(circle, #00ca10 10%, #4caf50 70%, #2e7d32 100%);
  }

  .connection-indicator.offline {
    background: radial-gradient(circle, #afafaf 10%, #9e9e9e 70%, #616161 100%);
  }
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .message-content {
    display: flex;
    flex-direction: column;
  }
  .message-username {
    font-weight: bold;
    margin-bottom: 4px;
  }

  .message-timestamp {
    font-size: 0.75em;
    color: gray;
    padding-left: 12px;
  }
</style>
