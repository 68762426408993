export const getNormalizedPath = (id1, id2) => {
  const [minId, maxId] = [id1, id2].sort((a, b) => a - b);
  return `${minId}/${maxId}/`;
};

export const initializeChatWebSocket = (senderId, receiverId) => {
  try {
    const path = getNormalizedPath(senderId, receiverId);
    const url = `wss://websocket.thanks-boss.com/ws/chat/${path}`;
    const webSocket = new WebSocket(url);

    webSocket.onopen = () => {
      console.log('WebSocket ouvert');
    };

    webSocket.onerror = (error) => {
      console.error('Error de WebSocket:', error);
    };
    webSocket.onclose = () => {
      console.log('WebSocket ferme');
    };

    return { webSocket };
  } catch (error) {
    console.error('Erreur lors de la initialisation du webSocket', error);
    return { webSocket: null };
  }
};

// Ce websocket est utilisé pour écouter les notifications de l'utilisateur et pour definir son état de connection
let retryCount = 0;
export const handleWebSocketUserConnection = () => {
  const maxRetries = 3; // Maximum des essayes de connection
  const retryDelay = 3000; // Delay pour les assay de connection

  const connectWebSocket = () => {
    try {
      const url = 'wss://websocket.thanks-boss.com/ws/user-notifications/';
      const webSocket = new WebSocket(url);

      webSocket.onopen = () => {
        console.log('WebSocket ouvert');
        retryCount = 0;
      };

      webSocket.onerror = (error) => {
        console.error('Error de WebSocket:', error);
      };

      webSocket.onclose = () => {
        console.log('WebSocket ferme');
        if (retryCount < maxRetries) {
          console.log(`Reconnexion du webSocket en ${retryDelay / 1000}s...`);
          setTimeout(() => {
            retryCount++;
            connectWebSocket();
          }, retryDelay);
        } else {
          console.error('Max des reconnections du webSocket dépassé.');
        }
      };

      return { webSocket };
    } catch (error) {
      console.error('Erreur lors de la initialisation du WebSocket', error);
      return { webSocket: null };
    }
  };

  return connectWebSocket();
};
